@import url('https://fonts.googleapis.com/css?family=Lato');

.navbar {
  margin-top: 0px;
  height:50px;
  background-color: #162A56;
}

.navbar ul {
  list-style: none;
}



.navbar li a {
  float: left;
  color: #f2f2f2;
  text-align: center;
  padding: 12.5px 16px;
  text-decoration: none;
  font-size: 17px;
  /* border-bottom: 3px solid transparent; */
}

.navbar a:hover {
  border-bottom: 5px solid white;
}

.active {
  border-bottom: 5px solid white;
}
