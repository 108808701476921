@import url('https://fonts.googleapis.com/css?family=Carme');
.contactInfo {
    font-family: 'Carme';
    margin: 25px;
    padding-top: 20px;
}

.contactInfo-title {
    padding-bottom: 25px;
    text-align: left;
    font-size: 32px;
    margin-top: 50px;
    margin-left: 50px;
}

.contact-subtitle {
    text-align: left;
    font-size: 17px;
    margin-left: 50px;
    padding-bottom: 25px;
}

.contact-form {
    margin: 50px;
}

.contact-form form {
}

.contact-form label {
    font-size: 20px;
    margin-bottom: 20px;
}

.contact-form #contactInput {
    border-radius: 3px;
    border-style: solid;
    border-width: thin;
    border-color: gray;
    padding-left: 5px;
    margin: 10px;
    font-size: 20px;
    width: 300px;
}

.contact-form #messageText {
    border-radius: 3px;
    border-style: solid;
    border-width: thin;
    border-color: gray;
    padding-left: 5px;
    margin: 10px;
    font-size: 20px;
    width: 620px;
    height: 300px;
}

.contact-form #contactSubmitBtn {
    margin-top: 20px;
    font-family: 'Carme';
    font-size: 20px;
    width: 100px;
    height: 50px;
    color: #ffffff;
    background-color: #0099ff;
}
