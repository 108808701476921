@import url(https://fonts.googleapis.com/css?family=Lato);
@import url(https://fonts.googleapis.com/css?family=Lato);
@import url(https://fonts.googleapis.com/css?family=Carme);
@import url(https://fonts.googleapis.com/css?family=Lato);
@import url(https://fonts.googleapis.com/css?family=Lato);
@import url(https://fonts.googleapis.com/css?family=Carme);
body {
  background-color: #0B72BD;
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.navbar {
  margin-top: 0px;
  height:50px;
  background-color: #162A56;
}

.navbar ul {
  list-style: none;
}



.navbar li a {
  float: left;
  color: #f2f2f2;
  text-align: center;
  padding: 12.5px 16px;
  text-decoration: none;
  font-size: 17px;
  /* border-bottom: 3px solid transparent; */
}

.navbar a:hover {
  border-bottom: 5px solid white;
}

.active {
  border-bottom: 5px solid white;
}


.homeInfo {
  background-color: white;
  font-family: 'Lato';
}

.headline {
  position: absolute;
  left: 20px;
  bottom: 20px;
  height: 100px;
  width: 400px;
  background-color: white;
  opacity: .8;
  flex-direction: column;
}

/* .homeInfo .largeImage img {
  object-fit: cover;
  width: 100%;
  height: 600px;
} */



body {
  font-family: "Lato";
  margin-bottom: 0px;
}

Link {
  -webkit-text-decoration: "none";
          text-decoration: "none";
}

img {
  object-fit: cover;
  width: 100%;
}

.about-info-body {
  background-color: white;
  font-family: 'Lato';
  padding-top: 20px;
}

.about-title {
  padding-left: 50px;
  padding-right: 50px;
  text-align: left;
  font-size: 28px;
}

.about-desc-desktop {
  padding-left: 500px;
  padding-right: 80px;
  padding-bottom: 40px;
}

.contactInfo {
    font-family: 'Carme';
    margin: 25px;
    padding-top: 20px;
}

.contactInfo-title {
    padding-bottom: 25px;
    text-align: left;
    font-size: 32px;
    margin-top: 50px;
    margin-left: 50px;
}

.contact-subtitle {
    text-align: left;
    font-size: 17px;
    margin-left: 50px;
    padding-bottom: 25px;
}

.contact-form {
    margin: 50px;
}

.contact-form form {
}

.contact-form label {
    font-size: 20px;
    margin-bottom: 20px;
}

.contact-form #contactInput {
    border-radius: 3px;
    border-style: solid;
    border-width: thin;
    border-color: gray;
    padding-left: 5px;
    margin: 10px;
    font-size: 20px;
    width: 300px;
}

.contact-form #messageText {
    border-radius: 3px;
    border-style: solid;
    border-width: thin;
    border-color: gray;
    padding-left: 5px;
    margin: 10px;
    font-size: 20px;
    width: 620px;
    height: 300px;
}

.contact-form #contactSubmitBtn {
    margin-top: 20px;
    font-family: 'Carme';
    font-size: 20px;
    width: 100px;
    height: 50px;
    color: #ffffff;
    background-color: #0099ff;
}

