@import url('https://fonts.googleapis.com/css?family=Lato');

.homeInfo {
  background-color: white;
  font-family: 'Lato';
}

.headline {
  position: absolute;
  left: 20px;
  bottom: 20px;
  height: 100px;
  width: 400px;
  background-color: white;
  opacity: .8;
  flex-direction: column;
}

/* .homeInfo .largeImage img {
  object-fit: cover;
  width: 100%;
  height: 600px;
} */
