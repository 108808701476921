@import url('https://fonts.googleapis.com/css?family=Lato');

.about-info-body {
  background-color: white;
  font-family: 'Lato';
  padding-top: 20px;
}

.about-title {
  padding-left: 50px;
  padding-right: 50px;
  text-align: left;
  font-size: 28px;
}

.about-desc-desktop {
  padding-left: 500px;
  padding-right: 80px;
  padding-bottom: 40px;
}
